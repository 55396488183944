import { LULCLayerType } from 'store/projects/types';
import { UploadFilePayload } from 'store/repositoryfile/types';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

// auth
export const RSA_URL = `${BASE_URL}/rsa`;
export const LOGIN_URL = `${BASE_URL}/login`;
export const LOGOUT_URL = `${BASE_URL}/logout`;
export const AES_KEY_URL = `${BASE_URL}/encrypt/`;
export const REFRESH_BOTH_TOKEN = `${BASE_URL}/refresh_both_tokens`;

// user
export const ADD_USER_DETAIL_URL = `${BASE_URL}/add_user_details`;

// projects
export const GET_ALL_PROJECT_URL = (orgId: number) =>
  `${BASE_URL}/get_all_projects?organisation_id=${orgId}`;
export const GET_ALL_PROJECTS_URL = `${BASE_URL}/projects/`;
export const ADD_PROJECT_URL = `${BASE_URL}/projects/`;
export const GET_PROJECT_URL = (projectId: number) => `${BASE_URL}/projects/${projectId}`;
export const GET_AVAILABLE_PROJECT_MEMBERS_URL = `${BASE_URL}/users`;
export const ADD_DEADLINE_STAGES_UPLOAD_URL = `${BASE_URL}/deadline_stages_upload`;
export const ADD_PROJECT_STAGE_CHANGE_STATUS_URL = `${BASE_URL}/change_status`;
export const GET_PROJECT_DETAILS_URL = `${BASE_URL}/projects/master-data`;
export const UPDATE_PROJECT_DETAILS_URL = (projectId: number) =>
  `${BASE_URL}/projects/${projectId}/edit`;
export const ADD_PROJECT_MEMBERS_URL = (projectId: number) =>
  `${BASE_URL}/projects/${projectId}/members`;
export const REMOVE_MEMBER_FROM_PROJECT_URL = (projectId: number) =>
  `${BASE_URL}/projects/${projectId}/member`;
export const REMOVE_MEMBER_FROM_ORGANIZATION_URL = (orgId: number, memberId: string) =>
  `${BASE_URL}/organisations/${orgId}/members/${memberId}/access`;
export const GET_PROJECT_ACTIVITY_LOG_URL = (projectId: number) =>
  `${BASE_URL}/activitylogs/${projectId}`;
export const CLOSED_PROJECT_URL = (projectId: number) => `${BASE_URL}/projects/${projectId}/close`;
export const OPEN_PROJECT_URL = (projectId: number) => `${BASE_URL}/projects/${projectId}/open`;
export const GET_PROJECT_EXTRACT_COORDS_URL = (projectId: number, repoFileId: number) =>
  `${BASE_URL}/projects/${projectId}/files/${repoFileId}/geometry`;
export const GET_LULC_LAYER_DETAILS_URL = (projectId: number, lulcType: LULCLayerType) =>
  `${BASE_URL}/projects/${projectId}/lulc/${lulcType}`;

// repository file
export const GET_ALL_FILES_URL = (projectId: number) => `${BASE_URL}/project/${projectId}/files`;
export const EDIT_REPOSITORY_FILE_URL = (projectId: number, repoFileId: number) =>
  `${BASE_URL}/project/${projectId}/file/${repoFileId}/edit`;
// WORK
export const ARCHIVE_REPOSITORY_FILE_URL = (projectId: number, repoFileId: number) =>
  `${BASE_URL}/project/${projectId}/file/${repoFileId}/active`;
export const GET_ALL_CATEGORIES_URL = (projectId: number) =>
  `${BASE_URL}/file-repo/get-file-categories/${projectId}`;
export const ADD_REPOSITORY_FILE_URL = (payload: UploadFilePayload) =>
  `${BASE_URL}/projects/${payload.project_id}/files?&category_id=${payload.category_id}&weight=${payload.weight}&buffer=${payload.buffer}&comment=${payload.comment}`;
export const FILE_MAP_ACCESS_URL = (projectId: number, repoFileId: number) =>
  `${BASE_URL}/project/${projectId}/file/${repoFileId}/map_access`;
export const DOWNLOAD_REPO_FILE_URL = (projectId: number, repoFileId: number) =>
  `${BASE_URL}/project/${projectId}/file/${repoFileId}/download`;
export const DELETE_REPOSITORY_FILE_URL = (projectId: number, repoFileId: number) =>
  `${BASE_URL}/project/${projectId}/file/${repoFileId}/delete`;

// aoi
export const AOI_COORDS_URL = (projectId: number) => `${BASE_URL}/projects/${projectId}/coords`;
export const GENERATE_AOI_URL = (projectId: number) =>
  `${BASE_URL}/aoi/${projectId}/aoi-generation`;
export const EDIT_AOI_URL = (projectId: number, aoiId: string) =>
  `${BASE_URL}/aoi/${projectId}/aois/${aoiId}/edit`;
export const GET_AOI_URL = (projectId: number) => `${BASE_URL}/aoi/${projectId}`;
export const APPROVE_DECLINE_AOI_URL = (projectId: number, aoiID: string) =>
  `${BASE_URL}/aoi/${projectId}/aois/${aoiID}/approval`;

// route
export const GET_ROUTE_URL = (projectId: number) => `${BASE_URL}/route/${projectId}`;
export const GENERATE_ROUTE_URL = (projectId: number) =>
  `${BASE_URL}/route/${projectId}/generate-route`;
export const EDIT_ROUTE_URL = (routeId: string) => `${BASE_URL}/route/${routeId}/edit-route`;
export const ROUTE_DETAILS_URL = (routeId: string) =>
  `${BASE_URL}/route/${routeId}/edit-route-stats`;
export const GET_ROUTE_TOWER_TYPE_OPTION_URL = `${BASE_URL}/route/tower-types`;
export const GET_TOWER_DEVIATION_ANGLE_URL = (
  firstpoint: string,
  secondpoint: string,
  thirdpoint: string,
  towerid: number
) =>
  `${BASE_URL}/route/get-tower-details?first_point=${firstpoint}&second_point=${secondpoint}&third_point=${thirdpoint}&id=${towerid}`;
export const UPDATE_ROUTE_TOWER_TYPE_URL = (towerid: number) =>
  `${BASE_URL}/route/towers/${towerid}`;
export const DOWNLOAD_ROUTE_DETAILS_URL = (routeId: string) =>
  `${BASE_URL}/route/${routeId}/export`;

// Workspace
export const BEELINE_COORDS_URL = (projectId: number) => `${BASE_URL}/projects/${projectId}/coords`;
export const LULC_LAYER_GENERATION_URL = (projectId: number) =>
  `${BASE_URL}/aoi/${projectId}/initiate-layer-generation`;

export const HIGH_RES_URL = `${BASE_URL}/aoi/initiate-high-res-generation`;
// analytics
export const GET_PROJECT_ANALYTICS_URL = (projectId: number) =>
  `${BASE_URL}/projects/${projectId}/analytics`;
export const ANALYTICS_UNIT_COST_URL = (projectId: number) =>
  `${BASE_URL}/projects/${projectId}/analytics/unit-cost`;

// geoserver/wms
export const GET_GEOSERVER_WMS_URL = `${BASE_URL}/geoserver/wms`;
export const GET_GEOSERVER_WMTS_URL = `${BASE_URL}/geoserver/wmts`;
export const GET_GEOSERVER_LAYER_STYLE_URL = (layer: string) =>
  `${BASE_URL}/geoserver/layer-legend?layer_name=${layer}`;

// google base layer url
export const roadmapUrl = 'http://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}';
export const hybridUrl = 'http://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}';
export const satelliteUrl = 'http://mt{0-3}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}';
export const geotiffUrl =
  'https://sentinel-cogs.s3.us-west-2.amazonaws.com/sentinel-s2-l2a-cogs/43/P/DR/2021/1/S2B_43PDR_20210101_0_L2A/B01.tif';

// LULC low/high res
// http://35.200.193.245:8080/data
export const LulcLowHighrResUrl = (layerName: string) =>
  `https://tileserver-adani.satsure.co/data/${layerName}/{z}/{x}/{y}.pbf`;

export const RasterURL =
  'https://sentinel-cogs.s3.us-west-2.amazonaws.com/sentinel-s2-l2a-cogs/36/Q/WD/2020/7/S2A_36QWD_20200701_0_L2A/TCI.tif';
// export const RasterURL =
//   'https://satsure-satimg.s3.ap-south-1.amazonaws.com/IS51011/T42RVN/20210627_T42RVN_IS51011.tif?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEAkaCmFwLXNvdXRoLTEiRzBFAiBsCsQcdsYCG%2FIZt1t4RKo5IbY5%2BiKol0J5Z3BZc4YWRAIhANCql7BVwGvhjyhQebw4xxdqNFM2L4b6MFKa6xGelH8SKuwDCNL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQARoMNzIzNDIwNDE0NjY3IgwaIaJXBTYG%2FvN%2B280qwAOBt7u9e2jLIZ4uzqnqBPdOp5mwl1hzW%2FvHdWXOKx%2F4el98M%2BZpsahtRJWJwflougimlvWGgmV%2BdXufmrEzvULRw4OwZTkl6jyydwcwG4TK4mf44MoMv6fhhL9yD75a6XJBwJ6MZ5vphi5rqP2Op9MD3KvH396HyJwrzJ4zct%2B3US%2FQUFIKrhI3AAzrq%2FYZUR0X6iubs%2F2csDqlM4gw21u9TuGomRsLYq47dltyGbiiaF%2FodxIhQHXfPSqO0UvZYXqQA%2FLuft8dQxu%2Ffbk7bxTDcXEdylHIGsIllD0ous9jAMnVUuQA7p8lydlWJ0vB6MmTcuRRvlmGO7Dn09uGSJVTTNWmMger3bwSai%2BbZUQiU1dPMnJ7bmqCZJlHjNH5oZROf1BM4hvoL4XYPWzUf3eAdaNBYc3MAvmW7SIjf4Z5UFhgNoEcvAn4s4C%2BYa2uaQzVbaNoY9tZFDiJdDbXFL%2FKZNv413DWT1smuJAXK%2BcUpcDCC9seOL8arsvarVMcE2UwHdV8q3irf2zUXfgY8X9SKSbU3VJGdC0x7d8X29aedMNbyrARs8EI6VW4oRQ5VADblW%2BUBYtWr8nNAqFZ6JKQMMbg2LQGOpQCsgx2PHPUE62zddoVWFoX0LTD1XE1Wzz8OZLBM8IHg%2BK1xDQiXPjSbMWQgs%2FjVTvStERCqpGFH1VObYn3rmuR6YmxKB0zgfOFAZqIK%2FTUnHJOckiuv9oQry%2FpPLhiPc%2Fqbk1x1osA9yvPwzsQp2U5Ovvks3aRjpbvD3T9220Vv4MRntHyTImcdA6cSFu8PGygalCkG0Zdp9yWhySsHyu4lg001Bkk3ORxw4n2rOGpJuakhz89o%2FNxz%2FfhjeA0cttfEd3l9b%2FrkKtwqLT%2BiRzwuNWjqhKCjU2oEHdKF0Q0GGfI2r6tTZrXgNKCTu0ftoFTUu5JKqqCROTWARCo%2BqNDRp18p0WSTZA0HekFSB7WttMq1M5N&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240716T083347Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIA2Q3ZXQLF2HU6OXKO%2F20240716%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=648fd9c8ca62232ef58e18182d370fed93ab87ed4699eedbcb5da5a1a5e43e42';
