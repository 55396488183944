import { TeamMember } from 'store/projects/types';
import { LatLongType } from 'types/aoi.types';

export enum AOIStausType {
  requested = 'Requested',
  generated = 'Generated',
  sentforapproval = 'Sent For Approval',
  pmapproved = 'PM Approved',
  wmapproved = 'WM Approved',
  declined = 'Declined',
  failed = 'Failed'
}

export const AOIStaus = {
  sentforapproval: 'SENT_FOR_APPROVAL',
  pmapproved: 'PM_APPROVED',
  wmapproved: 'WM_APPROVED',
  declined: 'DECLINED'
};

export interface AOIRouteStatusType {
  generation_percentage: number;
  status: string;
  error_msg: string;
  current_airflow_task: string;
}

export interface BeeLinePayload {
  start_point?: string;
  end_point?: string;
}

export interface ApproveDeclineAoiPayload {
  status: string;
  comment: string;
}

export interface BeeLineData {
  id: number;
  name: string;
  line_voltage_id: number;
  circuit_type_id: number;
  start_point: any;
  end_point: any;
  bid_submission_date: string;
  created_at: string;
  modified_at: string;
  is_closed: boolean;
}

export interface BeeLineAoiPoints {
  file_id: number;
  uploaded_by: string;
}

export interface AoiPointsStartEnd {
  coordinates: LatLongType | null;
  name: string | null;
  file_id?: number;
  uploaded_by?: string;
}

export interface HiResPayload {
  project_id: string;
  aoi_id: string;
}

export interface GenerateAoiPayload {
  file_id: number;
  weight: number;
  buffer: number;
  file_name: string;
  file_cat: string;
}

export interface StatsType {
  file_cat_id: number;
  file_cat_name: string | undefined;
  area_km_sq: number;
  id: number;
}

export interface AoiCardType {
  title: string;
  stats: StatsType[];
}

export interface EditAoiPayload {
  coordinates: any;
}

export interface AoiSelectionResponse {
  aoi_file_id: number;
  is_approved: boolean;
  submitted_by: string;
}

export interface GeometryType {
  type: string;
  coordinates: any;
}

export interface AOIDetailType {
  id: string;
  requested_at: string;
  requested_by: TeamMember | null;
  parameters: {
    vectors: GenerateAoiPayload[];
  };
  status: string;
  updated_at: string | null;
  updated_by: TeamMember | null;
  comment: string | null;
  geometry: GeometryType;
  current_airflow_task: string | null;
  error_msg: string | null;
  generation_percentage: string | null;
}

export interface AOIDetail extends AOIDetailType {
  stats: StatsType[];
  parent_aoi_id: string | null;
  color: string;
  index: number;
}

export type AoiState = {
  isRequestingAoiPointsCoords: boolean;
  isRequestingApprovedAoi: boolean;
  isRequestingGenerateLULCLayer: boolean;
  isRequestingGenerateAOI: boolean;
  aoiLists: AOIDetail[] | [];
  isRequestingAoiFiles: boolean;
  isRequestingAppproveDeclineAOI: boolean;
  isRequestingEditAOI: boolean;
  isRequestHighRes: boolean;
};

export interface AOICompareType {
  id: number;
  name: string;
  area_of_interest_sq_km: string;
  cost_of_imagery_approx_inr: string;
  weights: {
    railway_network: string;
    road_network: string;
    lake: string;
    airports: string;
    span_towers: string;
    agricultural_land: string;
    shrubs: string;
  };
  area_crossings_sqkm: {
    social_forest: string;
    shrubs: string;
    lake: string;
    national_park: string;
    agricultural_land: string;
    reserved_forest: string;
    built_up: string;
    canal: string;
  };
}
