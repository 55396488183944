import { filter, keys } from 'lodash';
import { black } from 'constants/theme.constants';
import { useAppSelector } from 'hooks/useReduxHook';
import { CategoryColor, FileType, RepositoryFile } from 'store/repositoryfile/types';
import { getLayersGroupByCategory } from 'store/repositoryfile';
import { LulcLowHighrResUrl } from 'utils/apiUrls';
import TileLayer from './TileLayer';

interface RenderHighResLayerProps {
  vectorLayerCategories: Array<string>;
}

const RenderHighResLayer = ({ vectorLayerCategories }: RenderHighResLayerProps) => {
  const layerCategory: {
    [key: string]: RepositoryFile[];
  } = useAppSelector((state) => getLayersGroupByCategory(state));

  return keys(layerCategory)
    .sort()
    .map((category: string) => {
      const files = layerCategory[category];
      // eslint-disable-next-line
      return filter(files, ['type', FileType.lulchigh]).map((data: RepositoryFile) => {
        if (vectorLayerCategories.includes(data.category)) {
          return (
            <TileLayer
              key={`high-${data.id}`}
              uniqueName={`${data.wms_layer_name}-${data.id}`}
              layerUrl={LulcLowHighrResUrl(data.wms_layer_name!)}
              layercolor={CategoryColor[`${data.category}`] || black}
            />
          );
        }
      });
    });
};

export default RenderHighResLayer;
