import { UserRole } from 'store/users/types';
import { CoordinateType } from 'types/common.types';

// export enum FilesCategory {
//   Start = 'Start Point',
//   End = 'End Point',
//   Bee = 'Bee-Line',
//   AOI = 'Area of Interest (AOI)',
//   LULC = 'LULC',
//   Routes = 'Routes',
//   NoGoZone = 'Others - No Go Zone',
//   Buffer = 'Others - Buffer Zone',
//   TL1200KV = '1200kV Transmission Lines',
//   DistributionLines = 'Distribution Lines',
//   Telecommunication = 'Telecommunication Lines',
//   TelecomTowers = 'Telecom Towers',
//   Airports = 'Airports',
//   DistributionTowers = 'Distribution Towers',
//   Forests = 'Forests',
//   Railways = 'Railways',
//   Roadways = 'Roadways',
//   RoutesFeatureList = 'Routes Feature List',
//   SolarPlant = 'Solar Plant',
//   TransmissionTowers = 'Transmission Towers',
//   WindTurbines = 'Wind Turbines',
//   WaterBodies = 'Water Bodies',
//   WildlifeSanctuary = 'Wildlife Sanctuary',
//   DEM = 'DEM',
//   DSM = 'DSM',
//   HighResolutionSataliteImages = 'High Resolution Satellite Images',
//   TL132KV = '132 kV Line', // new adani
//   TL220KV = '220 kV Line',
//   TL400KV = '400 kV Line',
//   TL765KV = '765 kV Line',
//   LTLine = 'LT Line',
//   RailwayLine = 'Railway Lines',
//   River = 'River',
//   NationalHighway = 'National Highway',
//   StateHighway = 'State Highway',
//   DefenceLand = 'Defence Land',
//   Other = 'Others',
//   AngleSchedule = 'Angle Schedule',
//   OrchardsPlantations = 'Orchards & Plantations', // new 26jun
//   FieldCropFallow = 'Field Crops / Fallow',
//   ManmadeConstructions = 'Manmade Constructions',
//   CRZ = 'CRZ',
//   DefenceHousingColony = 'Defence Housing / Colony',
//   SocialForest = 'Social Forest',
//   ReserveForest = 'Reserve Forest',
//   ProtectedArea = 'Protected Area',
//   OpenActiveMine = 'Open / Active Mine',
//   DeadInactiveMine = 'Dead Mine / Inactive Mine',
//   GaugeLines = 'Gauge Lines',
//   MetroLine = 'Metro Line',
//   Bridges = 'Bridges',
//   MDRVillageRoadsCT = 'MDR, Village Roads, CT',
//   SH = 'SH',
//   NHExpressWay = 'NH / Express Way',
//   ShrubsBarren = 'Shrubs / Barren',
//   WetLand = 'Wet Land',
//   HardRock = 'Soil Type - Hard Rock',
//   SandDunes = 'Soil Type - Sand Dunes',
//   MarshyLand = 'Soil Type - Marshy Land',
//   SaltPanes = 'Soil Type - Salt Panes',
//   SolarPark = 'Solar Park',
//   RailwayStations = 'Railway Stations',
//   Airport = 'Airport',
//   Helipads = 'Helipads',
//   Pipelines = 'Pipelines',
//   OverheadCrossing = 'Overhead Crossing',
//   LT = 'Low Tension Power Lines',
//   HTL = 'HTL',
//   UnderCrossing = 'Under Crossing',
//   Pond = 'Pond',
//   MinorRiver = 'Minor River',
//   MajorRiver = 'Major River',
//   Canal = 'Canal',
//   Dams = 'Dams',
//   Lake = 'Lake',
//   Reservoir = 'Reservoir',
//   Nalla = 'Nalla',
//   OverheadWaterTank = 'Overhead Water Tank ',
//   CreekArea = 'Creek Area',
//   NationalParkBoundaries = 'National Park Boundaries',
//   ConservationReserve = 'WFS - Conservation Reserve',
//   WindTower = 'Wind Tower',
//   HTTower = 'HT Tower',

// }

export enum FilesCategory {
  AgriLandOrchardsPlantations = 'Agri Land - Orchards & Plantations',
  AgriLandFieldCrops = 'Agri Land - Field Crops',
  BuildingsManmadeStructure = 'Buildings / Manmade Structure',

  ForestProtectedArea = 'Forest - Protected Area',
  Mine = 'Mine',
  DeadMineInactiveMine = 'Dead Mine / Inactive Mine',
  RailwayLines = 'Railway Lines',

  RoadsMDRVillageRoadsCT = 'Roads - MDR, Village Roads, CT',
  RoadsStateHighwaySH = 'Roads - State Highway SH',
  RoadsNationalHighwayExpressway = 'Roads - National Highway / Expressway',
  Shrubs = 'Shrubs',
  SoilTypeHardRock = 'Soil Type - Hard Rock',
  SoilTypeSandDunes = 'Soil Type - Sand Dunes',
  SoilTypeMarshyLand = 'Soil Type - Marshy Land',
  SoilTypeSaltPanes = 'Soil Type - Salt Panes',

  LowTensionPowerLines = 'Low Tension Power Lines',
  PowerLines = 'Power Lines',

  WFSConservationReserve = 'WFS - Conservation Reserve',
  kVLine132 = '132 kV Line',
  kVLine220 = '220 kV Line',
  kVLine400 = '400 kV Line',
  kVLine765 = '765 kV Line',

  BuiltupCluster = 'Builtup Cluster',
  Start = 'Start Point',
  End = 'End Point',
  Bee = 'Bee-Line',
  AOI = 'Area of Interest (AOI)',
  LULC = 'LULC',
  Routes = 'Routes',
  NoGoZone = 'Others - No Go Zone',
  Buffer = 'Others - Buffer Zone',
  TL1200KV = '1200kV Transmission Lines',
  DistributionLines = 'Distribution Lines',
  Telecommunication = 'Telecommunication Lines',
  TelecomTowers = 'Telecom Towers',
  Airports = 'Airports',
  DistributionTowers = 'Distribution Towers',
  Forests = 'Forests',
  Railways = 'Railways',
  Roadways = 'Roadways',
  RoutesFeatureList = 'Routes Feature List',
  SolarPlant = 'Solar Plant',
  HTTransmissionTowers = 'High Tension Transmission Tower',
  WindTurbines = 'Wind Turbines',
  WaterBodies = 'Water Bodies',
  WildlifeSanctuary = 'Wildlife Sanctuary',
  DEM = 'DEM',
  DSM = 'DSM',
  HighResolutionSataliteImages = 'High Resolution Satellite Images',
  TL132KV = '132 kV Line', // new adani
  TL220KV = '220 kV Line',
  TL400KV = '400 kV Line',
  TL765KV = '765 kV Line',
  LTLine = 'LT Line',
  RailwayLine = 'Railway Lines',
  River = 'River',
  NationalHighway = 'National Highway',
  StateHighway = 'State Highway',
  DefenceLand = 'Defence Land',
  Other = 'Others',
  AngleSchedule = 'Angle Schedule',
  OrchardsPlantations = 'Orchards & Plantations', // new 26jun
  FieldCropFallow = 'Field Crops / Fallow',
  ManmadeConstructions = 'Manmade Constructions',
  CRZ = 'CRZ',
  DefenceHousingColony = 'Defence Housing / Colony',
  SocialForest = 'Social Forest',
  ReserveForest = 'Reserve Forest',
  ProtectedArea = 'Protected Area',
  OpenActiveMine = 'Open / Active Mine',
  DeadInactiveMine = 'Dead Mine / Inactive Mine',
  GaugeLines = 'Gauge Lines',
  MetroLine = 'Metro Line',
  Bridges = 'Bridges',
  MDRVillageRoadsCT = 'MDR, Village Roads, CT',
  SH = 'SH',
  NHExpressWay = 'NH / Express Way',
  ShrubsBarren = 'Shrubs / Barren',
  WetLand = 'Wet Land',
  HardRock = 'Soil Type - Hard Rock',
  SandDunes = 'Soil Type - Sand Dunes',
  MarshyLand = 'Soil Type - Marshy Land',
  SaltPanes = 'Soil Type - Salt Panes',
  SolarPark = 'Solar Park',
  RailwayStations = 'Railway Stations',
  Airport = 'Airport',
  Helipads = 'Helipads',
  Pipelines = 'Pipelines',
  OverheadCrossing = 'Overhead Crossing',
  LT = 'Low Tension Power Lines',
  HTL = 'HTL',
  UnderCrossing = 'Under Crossing',
  Pond = 'Pond',
  MinorRiver = 'Minor River',
  MajorRiver = 'Major River',
  Canal = 'Canal',
  Dams = 'Dams',
  Lake = 'Lake',
  Reservoir = 'Reservoir',
  Nalla = 'Nalla',
  OverheadWaterTank = 'Overhead Water Tank ',
  CreekArea = 'Creek Area',
  NationalParkBoundaries = 'National Park Boundaries',
  ConservationReserve = 'WFS - Conservation Reserve',
  WindTower = 'Wind Tower',
  HTTower = 'HT Tower',
  SubStation = 'Sub-Station',
  AirportBoundary = 'Airport Boundary'
}

export enum FileType {
  vector = 'user_vector',
  lulclow = 'low_res_lulc',
  lulchigh = 'high_res_lulc'
}

export interface RepositoryFile {
  default_buffer: any;
  buffer: number;
  weight: number;
  category: string;
  comment: string;
  created_at: string;
  created_by: string;
  employee_id: string;
  active: boolean;
  file_name: string;
  first_name: string;
  id: number;
  last_name: string;
  url: string;
  user_email: string;
  type: FileType;
  user_role: UserRole;
  wms_layer_name: string | null;
  modified_at: string | null;
  color?: string;
  length?: number;
  coordinates?: { coordinates: any; type: CoordinateType }[];
  feature?: any;
}

export interface UploadRepoFile {
  category_id: number;
  buffer: string;
  comment: string;
  weight: number;
}

export interface UploadRepositoryFile extends UploadRepoFile {
  file: any;
  category_id: number;
  buffer: string;
  comment: string;
  weight: number;
}

export interface UploadFilePayload extends UploadRepoFile {
  [x: string]: any;
  project_id: number;
}

export interface FileEditPayload {
  filename: string;
  category_id: number;
  buffer: string;
  weight: number;
  comment: string;
}

export interface FilePayload extends FileEditPayload {
  user_id: string;
}

export interface FileArchivePayload {
  category_id: number;
}

export interface ArchivePayload extends FileArchivePayload {
  user_id: string;
  project_id: number;
}

export interface FileMapAccessPayload {
  user_id: string;
  enable: boolean;
}

export interface Category {
  category_name: string;
  id: number;
  default_buffer: number;
  weight: number;
}

export interface UploadFileResponse {
  file_id: number;
}

export interface DownloadFileResponse {
  file_name: string;
  url: string;
}

export interface DeleteRepositoryFile {
  user_id: string;
  category_id: number;
}

export interface RepositoryFileState {
  isRequestingFiles: boolean;
  repositoryFiles: Array<RepositoryFile>;
  isRequestingEditRepoFile: boolean;
  isRequestingAddRepoFile: boolean;
  isRequestingFileMapAccess: boolean;
  isRequestingCategory: boolean;
  isRequestingDownloadFile: boolean;
  isRequestingDeleteFile: boolean;
  categories: Category[];
  isRequestingGeoserverLayerStyles: boolean;
  geoserverLayerStyles: string[];
}

export const CategoryColor: { [key: string]: string } = {
  [FilesCategory.AgriLandOrchardsPlantations]: '#FDF317',
  [FilesCategory.AgriLandFieldCrops]: '#FEFF73',
  [FilesCategory.BuildingsManmadeStructure]: '#FE0000',
  [FilesCategory.CRZ]: '#CD00B9',
  [FilesCategory.DefenceLand]: '#5F005B',
  [FilesCategory.DefenceHousingColony]: '#8B0000',
  [FilesCategory.SocialForest]: '#267300',
  [FilesCategory.ReserveForest]: '#96E78A',
  [FilesCategory.ForestProtectedArea]: '#CB4141',
  [FilesCategory.Mine]: '#734501',
  [FilesCategory.DeadMineInactiveMine]: '#800000',
  [FilesCategory.RailwayLines]: '#0070D7',
  [FilesCategory.MetroLine]: '#8CA302',
  [FilesCategory.Bridges]: '#7400CF',
  [FilesCategory.RoadsMDRVillageRoadsCT]: '#EC994D',
  [FilesCategory.RoadsStateHighwaySH]: '#DE582E',
  [FilesCategory.RoadsNationalHighwayExpressway]: '#FFEC45',
  [FilesCategory.Shrubs]: '#B5D528',
  [FilesCategory.WetLand]: '#185E4F',
  [FilesCategory.SoilTypeHardRock]: '#FF96E8',
  [FilesCategory.SoilTypeSandDunes]: '#E5CFFE',
  [FilesCategory.SoilTypeMarshyLand]: '#D209FF',
  [FilesCategory.SoilTypeSaltPanes]: '#E0ABF1',
  [FilesCategory.SolarPark]: '#C98544',
  [FilesCategory.RailwayStations]: '#D3D3D3',
  [FilesCategory.Airport]: '#909090',
  [FilesCategory.Helipads]: '#AE48FF',
  [FilesCategory.Pipelines]: '#B88F00',
  [FilesCategory.OverheadCrossing]: '#8002E3',
  [FilesCategory.LowTensionPowerLines]: '#0066FF',
  [FilesCategory.PowerLines]: '#4682B4',
  [FilesCategory.UnderCrossing]: '#6B8E23',
  [FilesCategory.Pond]: '#5DD8FF',
  [FilesCategory.MinorRiver]: '#1E90FF',
  [FilesCategory.River]: '#003DDE',
  [FilesCategory.Canal]: '#B8D600',
  [FilesCategory.Dams]: '#00A884',
  [FilesCategory.Lake]: '#35B5DE',
  [FilesCategory.Reservoir]: '#6398FE',
  [FilesCategory.Nalla]: '#A4A0F2',
  [FilesCategory.OverheadWaterTank]: '#8002E3',
  [FilesCategory.CreekArea]: '#E9D62C',
  [FilesCategory.NationalParkBoundaries]: '#7AC800',
  [FilesCategory.WFSConservationReserve]: '#47DEBA',
  [FilesCategory.WindTower]: '#A10000',
  [FilesCategory.kVLine132]: '#67C9D7',
  [FilesCategory.kVLine220]: '#1DA7C6',
  [FilesCategory.kVLine400]: '#1F76B5',
  [FilesCategory.kVLine765]: '#043E95',
  [FilesCategory.AngleSchedule]: '#D2B48C',
  [FilesCategory.HTTransmissionTowers]: '#0862E8',
  [FilesCategory.BuiltupCluster]: '#CE0000',
  [FilesCategory.SubStation]: '#B6002C',
  [FilesCategory.AirportBoundary]: '#4D4D4D'
};
