import React, { useEffect, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import Down from 'assets/svg/DownAnalytics.svg';
import { useAppSelector } from 'hooks/useReduxHook';
import { AOIDetail } from 'store/aoi/types';
import { getSelectedAOI, getDropDownOptions, getGeneratedAOI } from 'store/aoi';
import { getAOIArea } from 'utils/util';

const AoiTab = () => {
  const aoiLists = useAppSelector(getGeneratedAOI);
  const [selectedAOIIndex, setSelectedAOIIndex] = useState<string[]>([]);
  const selectedAOIList = useAppSelector((state) => getSelectedAOI(state, selectedAOIIndex));
  const dropDownOptions = useAppSelector((state) => getDropDownOptions(state, selectedAOIIndex));

  useEffect(() => {
    setSelectedAOIIndex(aoiLists.slice(0, 3).map((aoi: AOIDetail) => aoi.id));
  }, [aoiLists]);

  const onDropDownHandle = (event: any, index: number) => {
    if (index !== -1) {
      const updatedAOIIDs = [...selectedAOIIndex];
      updatedAOIIDs[index] = event.key;
      setSelectedAOIIndex(updatedAOIIDs);
    }
  };

  if (!aoiLists || aoiLists.length === 0) {
    return <div className="no_data">No Data Available</div>;
  }

  return (
    <div className="aoiTab">
      <div>
        <div>
          <div>Compare AOI</div>
          <div>Area of Interest(sq km)</div>
        </div>

        {selectedAOIList.map((aoi: any, index: number) => (
          <div key={`heading_${aoi.id}`}>
            <div className="analyticTitleName" style={{ background: aoi.color }}>
              AOI {aoi.index}
              {aoiLists && aoiLists.length > 3 && (
                <span>
                  <Dropdown
                    overlay={
                      <Menu>
                        {dropDownOptions.map((option: { label: string; value: string }) => (
                          <Menu.Item key={option.value} onClick={(e) => onDropDownHandle(e, index)}>
                            {option.label}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                    trigger={['click']}>
                    <span style={{ fontWeight: 600 }}>
                      <img src={Down} alt="Down Analytics" style={{ marginLeft: 10 }} />
                    </span>
                  </Dropdown>
                </span>
              )}
            </div>
            <div>{getAOIArea(aoi?.geometry)}</div>
          </div>
        ))}
      </div>
      <div className="aoiTabScrollable">
        <div>
          <div className="analyticSubTitleName">Weights</div>
          {selectedAOIList[0]?.weights?.map((aoi: any, index) => (
            <div key={`stat_name_${aoi.file_cat}${index}`}>
              <div>{aoi.file_cat}</div>
            </div>
          ))}
          <div className="analyticSubTitleName">Area Crossings(sq km)</div>
          {selectedAOIList[0]?.stats?.map((aoi: any) => (
            <div key={aoi.index} data-testid="stats-name">
              <div>{aoi.file_cat_name}</div>
            </div>
          ))}
        </div>
        {selectedAOIList.map((aoi: any, index: number) => (
          <div key={index}>
            <div>&nbsp;</div>
            {aoi.weights.map((item: any, index: number) => (
              <div key={`weight_${index}`}>{item.weight}</div>
            ))}
            <div>&nbsp;</div>
            {aoi.stats.map((item: any, index: number) => (
              <div key={index}>{item.area_km_sq.toFixed(2)}</div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AoiTab;
