import { useState } from 'react';
import { Progress, Typography } from 'antd';
import { find, round } from 'lodash';
import { BsDot } from 'react-icons/bs';
import CloseIcon from 'assets/svg/CloseIcon';
import { green, lightred, primary } from 'constants/theme.constants';
import { useAppSelector } from 'hooks/useReduxHook';
import { LULCLayerType, ProjectStagesList, ProposedTimelineStatus } from 'store/projects/types';

const { Text } = Typography;

interface LulcProgressStatsType {
  currentStep: number;
  isLulcGenInprogress: boolean;
  isAOIGenerationNotStarted: boolean;
}

const LulcProgressStats = ({
  currentStep,
  isLulcGenInprogress,
  isAOIGenerationNotStarted
}: LulcProgressStatsType) => {
  const { selectedProject, LulcLayerDetails } = useAppSelector((state) => state.projects);
  const [close, setClose] = useState(true);
  const isRouteGenerationNotStarted = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.RouteGeneration,
      status: ProposedTimelineStatus.NotStarted
    })
  );

  return currentStep !== 0 &&
    (isLulcGenInprogress || isAOIGenerationNotStarted || isRouteGenerationNotStarted) ? (
    <>
      {close && LulcLayerDetails && LulcLayerDetails.generation_percentage === 100 && (
        <div className="complete-layer-info">
          <div>LULC generated successfully, now you can generate AOI.</div>
          <CloseIcon height={8} width={8} color="#2f80ed" onClick={() => setClose(false)} />
        </div>
      )}
      {LulcLayerDetails && (
        <div className="fetch-layer-info">
          <div>
            <Text>
              {LulcLayerDetails && LulcLayerDetails.generation_percentage === 100
                ? 'Generated '
                : 'Generating '}
              {LulcLayerDetails &&
                LulcLayerDetails.lulc_type === LULCLayerType.lulclow &&
                'Low Res '}
              {LulcLayerDetails &&
                LulcLayerDetails.lulc_type === LULCLayerType.lulchigh &&
                'High Res '}
              LULC...
            </Text>
            <Text>
              {LulcLayerDetails && LulcLayerDetails.generation_percentage
                ? round(LulcLayerDetails.generation_percentage)
                : 0}
              %
            </Text>
          </div>
          {LulcLayerDetails &&
            (LulcLayerDetails.status === 'Requested' ||
              LulcLayerDetails.status === 'Generated') && (
              <Progress
                strokeColor={
                  LulcLayerDetails && LulcLayerDetails.generation_percentage === 100
                    ? green
                    : primary
                }
                trailColor="#D9D9D9"
                percent={LulcLayerDetails.generation_percentage}
                showInfo={false}
              />
            )}
          {LulcLayerDetails && LulcLayerDetails.status === 'Failed' && (
            <div className="approve-dwnld" style={{ justifyContent: 'flex-start', paddingTop: 6 }}>
              <BsDot size={16} color={lightred} />
              <Text className="declined" style={{ color: lightred, width: '80%' }}>
                Failed to generate
              </Text>
            </div>
          )}
        </div>
      )}
    </>
  ) : null;
};

export default LulcProgressStats;
