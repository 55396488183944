import { useEffect, useRef, useState } from 'react';
import { isEmpty, keys } from 'lodash';
import { Button, Collapse, Dropdown, Menu, Tooltip, Typography, Spin } from 'antd';
import VerticalThreeDotIcon from 'assets/svg/VerticalThreeDotIcon';
import EyeCloseIconV1 from 'assets/svg/EyeCloseIconV1';
import EyeOpenIconV1 from 'assets/svg/EyeOpenIconV1';
import { black } from 'constants/theme.constants';
import RangeSlider from 'components/core/RangeSlider';
import { useAppSelector } from 'hooks/useReduxHook';
import { CategoryColor, RepositoryFile, RepositoryFileState } from 'store/repositoryfile/types';
import { ProjectState } from 'store/projects/types';
import { UserPermission } from 'store/users/types';

const { Panel } = Collapse;
const { Text } = Typography;

interface LayerListProps {
  selectedLayer: number | null;
  isRouteGeneration: boolean;
  isShowHideWeightSlider: boolean;
  layerWeight: number;
  layers: Array<RepositoryFile> | [];
  layerCategory: { [key: string]: RepositoryFile[] };
  vectorLayerCategories: string[];
  handleChangeLayerPosition: (id: number, weight: number) => void;
  handleFileWeightChange: (val: number) => void;
  handleAfterFileWeightChange: (val: number, file: RepositoryFile[]) => void;
  handleVectorLayerIds: (category: string) => void;
  handleShowHideLayers: (isshowhide: boolean) => void;
  handleEditBuffer: (file: RepositoryFile[]) => void;
  handleHideSlider: () => void;
}

const LayerList = ({
  isRouteGeneration,
  selectedLayer,
  isShowHideWeightSlider,
  layerWeight,
  layers,
  layerCategory,
  vectorLayerCategories,
  handleChangeLayerPosition,
  handleVectorLayerIds,
  handleShowHideLayers,
  handleFileWeightChange,
  handleAfterFileWeightChange,
  handleEditBuffer,
  handleHideSlider
}: LayerListProps) => {
  const { selectedProject }: ProjectState = useAppSelector((state) => state.projects);
  const { isRequestingFiles }: RepositoryFileState = useAppSelector((state) => state.repofiles);
  const [layerShowHide, setLayerShowHide] = useState<boolean>(false);
  const isViewer = selectedProject?.user_permission === UserPermission.Viewer;
  const sliderRef = useRef<HTMLDivElement | null>(null);

  const handleChangeAfterFileWeight = (val: number, files: RepositoryFile[]) => {
    handleAfterFileWeightChange(val, files);
  };

  const handleShowHideAllLayers = (isshowhide: boolean) => {
    setLayerShowHide(!layerShowHide);
    handleShowHideLayers(isshowhide);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (sliderRef.current && !sliderRef.current.contains(event.target as Node)) {
      handleHideSlider();
    }
  };

  useEffect(() => {
    if (isShowHideWeightSlider) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isShowHideWeightSlider]);

  return (
    <Collapse
      defaultActiveKey={['1']}
      collapsible="disabled"
      // eslint-disable-next-line
      expandIcon={() =>
        !isEmpty(layers) ? (
          <Button
            type="text"
            disabled={isEmpty(layers)}
            ghost
            onClick={() => handleShowHideAllLayers(!layerShowHide)}>
            {layerShowHide ? 'hide all' : 'show all'}
          </Button>
        ) : null
      }
      expandIconPosition="end"
      className={isShowHideWeightSlider ? 'layer-collapse sticky-layer' : 'layer-collapse'}>
      <Panel header="Layers" key="1">
        <div className="flexDirectionColumn layer-item-list">
          {!isEmpty(layers) ? (
            keys(layerCategory)
              .sort()
              .map((category: string) => {
                const file = layerCategory[category][0];

                return (
                  <div className="layer-list-item" key={`${file.id}-${file.category}`}>
                    <div>
                      <div
                        className="box-color"
                        style={{
                          backgroundColor: CategoryColor[`${file.category}`]
                            ? CategoryColor[`${file.category}`]
                            : black
                        }}
                      />
                      <div
                        style={{
                          width: isRouteGeneration ? '160px' : '170px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                        data-testid={`filename_${file.category}`}>
                        {file.category}
                      </div>
                    </div>
                    <div>
                      <Tooltip
                        placement="right"
                        overlayClassName="layer-pos-tooltip"
                        title="Weights">
                        <Text
                          className="layer-position"
                          onClick={() => handleChangeLayerPosition(file.id, file.weight || 0)}>
                          {(selectedLayer === file.id && layerWeight !== null
                            ? layerWeight
                            : file.weight) || 0}
                        </Text>
                      </Tooltip>
                      {vectorLayerCategories.includes(category) ? (
                        <EyeOpenIconV1
                          classname={isRouteGeneration ? 'route-eye' : ''}
                          width={12}
                          height={10}
                          onClick={() => handleVectorLayerIds(category)}
                        />
                      ) : (
                        <EyeCloseIconV1
                          classname={isRouteGeneration ? 'route-eye' : ''}
                          width={12}
                          height={12}
                          onClick={() => handleVectorLayerIds(category)}
                        />
                      )}
                      {isShowHideWeightSlider && selectedLayer === file.id && (
                        <Tooltip
                          placement="bottomLeft"
                          overlayClassName="layer-weight-tooltip"
                          open={isShowHideWeightSlider && selectedLayer === file.id}
                          title={
                            <div ref={sliderRef}>
                              <RangeSlider
                                handleChangeLayerPosition={handleFileWeightChange}
                                handleHideSlider={handleHideSlider}
                                handleAfterFileWeightChange={(val) =>
                                  handleChangeAfterFileWeight(val, layerCategory[category])
                                }
                                layerWeight={
                                  isShowHideWeightSlider && selectedLayer === file.id
                                    ? layerWeight
                                    : file.weight || 0
                                }
                              />
                            </div>
                          }
                        />
                      )}
                      {isRouteGeneration && (
                        <Dropdown
                          placement="bottomRight"
                          overlay={
                            <Menu
                              onClick={({ key }: any) => {
                                if (key === 'edit') {
                                  handleEditBuffer(layerCategory[category]);
                                }
                              }}>
                              <Menu.Item key="edit" disabled={isViewer}>
                                Edit Buffer
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={['click']}
                          overlayClassName="maplayer-dropdown">
                          <VerticalThreeDotIcon height={16} width={6} />
                        </Dropdown>
                      )}
                    </div>
                  </div>
                );
              })
          ) : (
            <div className="layer-list-item no-repo-file">
              {isRequestingFiles ? (
                <Spin spinning={isRequestingFiles} />
              ) : (
                <Text>Layer not generated</Text>
              )}
            </div>
          )}
        </div>
      </Panel>
    </Collapse>
  );
};

export default LayerList;
