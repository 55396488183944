// Async variable names.
export const ACCESS_TOKEN = 'ADANI_HTL_ACCESS_TOKEN';
export const REFRESH_TOKEN = 'ADANI_HTL_REFRESH_TOKEN';
export const AES_KEY = 'ADANI_HTL_AES_KEY';
export const USER_ROLE = 'ADANI_HTL_USER_ROLE';

// error
export const TOKEN_EXPIRE_ERROR = 'Token Already Expired';

// Date format
export const DateFormat1 = 'Do MMMM YYYY';
export const DateFormat2 = 'D MMM YYYY';
export const DateFormat3 = 'YYYY-MM-DD';
export const DateFormat4 = 'D MMM YYYY, HH:mm:ss';
export const DateFormat5 = 'MM/DD/YYYY HH:mm:ss';
export const DateFormat6 = 'DD/MM/YYYY';
export const TimeFormat = 'HH:mm:ss';

// Google map
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GMAP_KEY;
export const defaultCenter = {
  lat: '20.5937',
  lng: '78.9629'
};
export const mapStyleWithoutLabel = [{ elementType: 'labels', stylers: [{ visibility: 'off' }] }];

export const userRoleText: any = {
  workspace_manager: 'Workspace Manager',
  regular: 'User',
  manager: 'Manager'
};

export const aoiRouteColors = [
  '#983628',
  '#00BFBF',
  '#95753D',
  '#DD74DD',
  '#74FFFF',
  '#D171A8',
  '#AA94D0',
  '#993325',
  '#5AD5D5',
  '#7FFF7F',
  '#FF7F7F',
  '#4DDDDD',
  '#255ADF',
  '#0F7F7F',
  '#00C3B8',
  '#6532CD',
  '#B5974B',
  '#8F6F6F',
  '#2D4F73',
  '#A6005F',
  '#435070',
  '#00A483',
  '#780120',
  '#21C3C3',
  '#D0B0B0',
  '#B5A275',
  '#9441DC',
  '#B6A61E',
  '#74B5EC',
  '#2D96E1',
  '#DF4D55'
];

export const aoiBackColors: { [key: string]: string } = {
  '#983628': 'rgba(152, 54, 40, 0.2)',
  '#00BFBF': 'rgba(0, 191, 191, 0.2)',
  '#95753D': 'rgba(149, 117, 61, 0.2)',
  '#DD74DD': 'rgba(221, 116, 221, 0.2)',
  '#74FFFF': 'rgba(116, 255, 255, 0.2)',
  '#D171A8': 'rgba(209, 113, 168, 0.2)',
  '#AA94D0': 'rgba(170, 148, 208, 0.2)',
  '#993325': 'rgba(153, 51, 37, 0.2)',
  '#5AD5D5': 'rgba(90, 213, 213, 0.2)',
  '#7FFF7F': 'rgba(127, 255, 127, 0.2)',
  '#FF7F7F': 'rgba(255, 127, 127, 0.2)',
  '#4DDDDD': 'rgba(77, 221, 221, 0.2)',
  '#255ADF': 'rgba(37, 90, 223, 0.2)',
  '#0F7F7F': 'rgba(15, 127, 127, 0.2)',
  '#00C3B8': 'rgba(0, 195, 184, 0.2)',
  '#6532CD': 'rgba(101, 50, 205, 0.2)',
  '#B5974B': 'rgba(181, 151, 75, 0.2)',
  '#8F6F6F': 'rgba(143, 111, 111, 0.2)',
  '#2D4F73': 'rgba(45, 79, 115, 0.2)',
  '#A6005F': 'rgba(166, 0, 95, 0.2)',
  '#435070': 'rgba(67, 80, 112, 0.2)',
  '#00A483': 'rgba(0, 164, 131, 0.2)',
  '#780120': 'rgba(120, 1, 32, 0.2)',
  '#21C3C3': 'rgba(33, 195, 195, 0.2)',
  '#D0B0B0': 'rgba(208, 176, 176, 0.2)',
  '#B5A275': 'rgba(181, 162, 117, 0.2)',
  '#9441DC': 'rgba(148, 65, 220, 0.2)',
  '#B6A61E': 'rgba(182, 166, 30, 0.2)',
  '#74B5EC': 'rgba(116, 181, 236, 0.2)',
  '#2D96E1': 'rgba(45, 150, 225, 0.2)',
  '#DF4D55': 'rgba(223, 77, 85, 0.2)'
};
